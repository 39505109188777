import { ref } from 'vue';
import config from '~/config';
import listingRepo from '~/repositories/listing';

export const useListing = () => {
  const loading = ref(false);
  const portList: any = ref([]);
  const tripList: any = ref([]);
  const parcelTripList: any = ref([]);
  const passList: any = ref([]);
  const charterList: any = ref([]);
  const charterDetail: any = ref([]);
  const boatWiseVehicleList: any = ref([]);
  const boatWisePetList: any = ref([]);
  const boatWiseClassList: any = ref([]);
  const baseImageUrl = import.meta.env.VITE_APP_IMAGES_PATH;
  const testimonialList: any = ref([]);
  const topDestList: any = ref([]);
  const topNewsList: any = ref([]);
  const transactionDataList: any = ref([]);
  const profileTrips: any = ref([]);
  const profilePassList: any = ref([]);
  const profileCharterList: any = ref([]);
  const profileParcelList: any = ref([]);
  const timer = ref();
  const profileTripParams: any = reactive({
    data: {
      limit: 10,
      page: 1,
      total: 0,
      perpage: 10,
      sort: '',
      dir: '',
      search: '',
      type: '',
      status: '',
      from_date : '',
      to_date: '',
      from: 0,
      to: 0,
      links: [],
    },
  });
  const rowData: any = ref({});
  const getPortList = async () => {
    try {
      loading.value = true;
      const res = await listingRepo.getPortList();
      if (res.status === "success") {
        portList.value = res.data;
        loading.value = false;
      } else {
        $toast.error(res.message);
        loading.value = false;
      }
      // return await listingRepo.getProfileTrips();
    } catch (error: any) {
      loading.value = false;
      console.log(error);
    }
  };

  const getTripList = async (data: any) => {
    try {
      // loading.value = true
      const res = await listingRepo.getTripList(data);
      if (res.status === "success") {
        tripList.value = res.data;
        loading.value = false;
      } else {
        $toast.error(res.message);
        loading.value = false;
      }
      // return await listingRepo.getProfileTrips();
    } catch (error: any) {
      loading.value = false;
      console.log(error);
    }
  };

  const getParcelTripList = async (data: any) => {
    try {
      const res = await listingRepo.getParcelTripList(data);
      if (res.status === "success") {
        parcelTripList.value = res.data;
        loading.value = false;
      } else {
        $toast.error(res.message);
        loading.value = false;
      }
      // return await listingRepo.getProfileTrips();
    } catch (error: any) {
      loading.value = false;
      $toast.error(config.catchErrorMessage);
      console.log(error);
    }
  };

  const getPassList = async (data: any) => {
    try {
      const res = await listingRepo.getPassList(data);
      if (res.status === "success") {
        passList.value = res.data;
        loading.value = false;
      } else {
        $toast.error(res.message);
        loading.value = false;
      }
      // return await listingRepo.getProfileTrips();
    } catch (error: any) {
      loading.value = false;
      console.log(error);
    }
  };

  const getBoatsVehicles = async (boatId: string) => {
    try {
      const res = await listingRepo.getAllBoatVehicles(boatId);
      if (res.status === "success") {
        boatWiseVehicleList.value = res.data;
      } else {
        $toast.error(res.message);
      }
      loading.value = false;
      // return await listingRepo.getProfileTrips();
    } catch (error: any) {
      loading.value = false;
      console.log(error);
    }
  };

  const getBoatsPets = async (boatId: string) => {
    try {
      const res = await listingRepo.getAllBoatPets(boatId);
      if (res.status === "success") {
        boatWisePetList.value = res.data;
        loading.value = false;
      } else {
        $toast.error(res.message);
        loading.value = false;
      }
      // return await listingRepo.getProfileTrips();
    } catch (error: any) {
      loading.value = false;
      console.log(error);
    }
  };

  const getBoatsClasses = async (boatId: string) => {
    try {
      const res = await listingRepo.getAllBoatClasses(boatId);
      if (res.status === "success") {
        boatWiseClassList.value = res.data;
        loading.value = false;
      } else {
        $toast.error(res.message);
        loading.value = false;
      }
      // return await listingRepo.getProfileTrips();
    } catch (error: any) {
      loading.value = false;
      console.log(error);
    }
  };

  const getCharterList = async (data: any) => {
    try {
      const res = await listingRepo.getCharterList(data);
      if (res.status === "success") {
        charterList.value = res.data;
        loading.value = false;
      } else {
        $toast.error(res.message);
        loading.value = false;
      }
      // return await listingRepo.getProfileTrips();
    } catch (error: any) {
      loading.value = false;
      $toast.error(config.catchErrorMessage);
      console.log(error);
    }
  };

  const getCharterDetail = async (charterId: any) => {
    try {
      const res = await listingRepo.getCharterDetail(charterId);
      if (res.status === "success") {
        charterDetail.value = res.data;
        loading.value = false;
      } else {
        $toast.error(res.message);
        loading.value = false;
      }
    } catch (error: any) {
      loading.value = false;
      console.log(error);
    }
  };

  function showErrorImage(e: any, img: any) {
    e.target.src = img;
    // e.target.src = '/images/ticket-icon.png';
  }

  const getTransactionList = async () => {
    try {
      const res = await listingRepo.getTransactionList(profileTripParams.data);
      if (res.status === 'success') {
        transactionDataList.value = res.data.data;
        profileTripParams.data.total = res.data.total;
        profileTripParams.data.perpage = res.data.per_page;
        profileTripParams.data.from = res.data.from;
        profileTripParams.data.to = res.data.to;
        profileTripParams.data.links = res.data.links;
        loading.value = false;
      } else {
        $toast.error(res.message);
        loading.value = false;
      }
    } catch (error: any) {
      loading.value = false;
      console.log(error);
    }
  };

  const getProfileTrips = async () => {
    try {
      const res = await listingRepo.getProfileTrips(profileTripParams.data);
      if (res.status === 'success') {
        profileTrips.value = res.data.data;
        profileTripParams.data.total = res.data.total;
        profileTripParams.data.perpage = res.data.per_page;
        profileTripParams.data.from = res.data.from;
        profileTripParams.data.to = res.data.to;
        profileTripParams.data.links = res.data.links;
        loading.value = false;
      } else {
        $toast.error(res.message);
        loading.value = false;
      }
    } catch (error: any) {
      loading.value = false;
      console.log(error);
    }
  };

  const search = ref<string>('');
  const searchItems = () => {
    profileTripParams.data.page = 1;
    profileTripParams.data.search = search.value;
    loading.value = true;
    clearTimeout(timer.value);

    timer.value = setTimeout(async () => {
      await getProfileTrips();
      loading.value = false;
    }, 300);
  };

  const limitChange = async (val: number) => {
    profileTripParams.data.page = 1;
    profileTripParams.data.limit = val;
    await getProfileTrips();
  };
  const pageChange = async (val: number) => {
    profileTripParams.data.page = val;
    await getProfileTrips();
  };
  const clearSearch = async () => {
    search.value = '';
    profileTripParams.data.search = search.value;
    await getProfileTrips();
  };

  const newsParams = reactive({
    data: {
      limit: 10,
      page: 1,
      categorySlug: '',
      total: 0,
      perpage: 10,
      from: 0,
      to: 0,
      links: [],
    },
  });
  const { $toast } = useNuxtApp();
  const newsList: any = ref([]);
  const newsData: any = ref(null);
  const getNewsList = async () => {
    try {
      loading.value = true;
      const res = await listingRepo.getNewsList(newsParams.data);
      if (res.status === 'success') {
        newsList.value = res.data.data;
        newsParams.data.total = res.data.total;
        newsParams.data.perpage = res.data.per_page;
        newsParams.data.from = res.data.from;
        newsParams.data.to = res.data.to;
        newsParams.data.links = res.data.links;
        loading.value = false;
      } else {
        $toast.error(res.message);
        loading.value = false;
      }
    } catch (error: any) {
      $toast.error(config.catchErrorMessage);
      console.log(error);
      loading.value = false;
    }
  };

  const getNews = async (categoryslug: string, titleslug: string) => {
    try {
      loading.value = true;
      const res = await listingRepo.getNews(categoryslug, titleslug);
      if (res.status === 'success') {
        newsData.value = res.data;
        loading.value = false;
      } else {
        $toast.error(res.message);
        loading.value = false;
      }
    } catch (error: any) {
      $toast.error(config.catchErrorMessage);
      loading.value = false;
    }
  };

  const getInvoice = async (id: string) => {
    try {
      loading.value = true;
      const res = await listingRepo.getTransactionInvoice(id);
      const blob = new Blob([res], { type: 'application/pdf' });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = 'Transaction.pdf';
      link.click();
      // if (res.status === 'success') {
      //   let invoicePath = import.meta.env.VITE_APP_INVOICE_PATH;
      //   const path = invoicePath + res.data;
      //   window.open(path);
      //   loading.value = false;

      // } else {
      //   $toast.error(res.message);
      //   loading.value = false;
      // }
    } catch (error: any) {
      $toast.error(config.catchErrorMessage);
      loading.value = false;
    }
  };
  const getTripDetail = async (id: string) => {
    try {
      loading.value = true;
      const res = await listingRepo.getTripDetail(id);
      const blob = new Blob([res], { type: 'application/pdf' });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = 'Ticket-Invoice.pdf';
      link.click();

      // if (res.status === 'success') {
      //   let invoicePath = import.meta.env.VITE_APP_INVOICE_PATH;
      //   // newsData.value = res.data;
      //   const path = invoicePath + res.data;
      //   window.open(path);
      //   loading.value = false;

      // } else {
      //   $toast.error(res.message);
      //   loading.value = false;
      // }
    } catch (error: any) {
      $toast.error(config.catchErrorMessage);
      loading.value = false;
    }
  };
  const getTestimonialList = async () => {
    try {
      const res = await listingRepo.getTestimonialList();
      if (res.status === "success") {
        testimonialList.value = res.data;
        loading.value = false;
      } else {
        $toast.error(res.message);
        loading.value = false;
      }
    } catch (error: any) {
      loading.value = false;
      console.log(error);
    }
  };
  const getTopDestinations = async () => {
    try {
      const res = await listingRepo.getTopDestinations();
      if (res.status === "success") {
        topDestList.value = res.data;
        loading.value = false;
      } else {
        $toast.error(res.message);
        loading.value = false;
      }
    } catch (error: any) {
      loading.value = false;
      console.log(error);
    }
  };
  const getTopNews = async () => {
    try {
      const res = await listingRepo.getTopNews();
      if (res.status === "success") {
        topNewsList.value = res.data;
        loading.value = false;
      } else {
        $toast.error(res.message);
        loading.value = false;
      }
    } catch (error: any) {
      loading.value = false;
      console.log(error);
    }
  };
  const getPassListing = async () => {
    try {
      const res = await listingRepo.getPassListing(profileTripParams.data);
      if (res.status === 'success') {
        profilePassList.value = res.data.data;
        profileTripParams.data.total = res.data.total;
        profileTripParams.data.perpage = res.data.per_page;
        profileTripParams.data.from = res.data.from;
        profileTripParams.data.to = res.data.to;
        profileTripParams.data.links = res.data.links;
        loading.value = false;
      } else {
        $toast.error(res.message);
        loading.value = false;
      }
    } catch (error: any) {
      loading.value = false;
      console.log(error);
    }
  };
  const searchPass = () => {
    profileTripParams.data.page = 1;
    profileTripParams.data.search = search.value;
    loading.value = true;
    clearTimeout(timer.value);

    timer.value = setTimeout(async () => {
      await getPassListing();
      loading.value = false;
    }, 300);
  };
  const passLimitChange = async (val: number) => {
    profileTripParams.data.page = 1;
    profileTripParams.data.limit = val;
    await getPassListing();
  };
  const passPageChange = async (val: number) => {
    profileTripParams.data.page = val;
    await getPassListing();
  };
  const clearPassSearch = async () => {
    search.value = '';
    profileTripParams.data.search = search.value;
    await getPassListing();
  };
  const getCharterListing = async () => {
    try {
      const res = await listingRepo.getCharterListing(profileTripParams.data);
      if (res.status === 'success') {
        profileCharterList.value = res.data.data;
        profileTripParams.data.total = res.data.total;
        profileTripParams.data.perpage = res.data.per_page;
        profileTripParams.data.from = res.data.from;
        profileTripParams.data.to = res.data.to;
        profileTripParams.data.links = res.data.links;
        loading.value = false;
      } else {
        $toast.error(res.message);
        loading.value = false;
      }
    } catch (error: any) {
      loading.value = false;
      console.log(error);
    }
  };
  const searchCharter = () => {
    profileTripParams.data.page = 1;
    profileTripParams.data.search = search.value;
    loading.value = true;
    clearTimeout(timer.value);

    timer.value = setTimeout(async () => {
      await getCharterListing();
      loading.value = false;
    }, 300);
  };
  const charterLimitChange = async (val: number) => {
    profileTripParams.data.page = 1;
    profileTripParams.data.limit = val;
    await getCharterListing();
  };
  const charterPageChange = async (val: number) => {
    profileTripParams.data.page = val;
    await getCharterListing();
  };
  const clearCharterSearch = async () => {
    search.value = '';
    profileTripParams.data.search = search.value;
    await getCharterListing();
  };
  const getParcelListing = async () => {
    try {
      const res = await listingRepo.getParcelListing(profileTripParams.data);
      if (res.status === 'success') {
        profileParcelList.value = res.data.data;
        profileTripParams.data.total = res.data.total;
        profileTripParams.data.perpage = res.data.per_page;
        profileTripParams.data.from = res.data.from;
        profileTripParams.data.to = res.data.to;
        profileTripParams.data.links = res.data.links;
        loading.value = false;
      } else {
        $toast.error(res.message);
        loading.value = false;
      }
    } catch (error: any) {
      loading.value = false;
      console.log(error);
    }
  };
  const searchParcel = () => {
    profileTripParams.data.page = 1;
    profileTripParams.data.search = search.value;
    loading.value = true;
    clearTimeout(timer.value);

    timer.value = setTimeout(async () => {
      await getParcelListing();
      loading.value = false;
    }, 300);
  };
  const parcelLimitChange = async (val: number) => {
    profileTripParams.data.page = 1;
    profileTripParams.data.limit = val;
    await getParcelListing();
  };
  const parcelPageChange = async (val: number) => {
    profileTripParams.data.page = val;
    await getParcelListing();
  };
  const clearParcelSearch = async () => {
    search.value = '';
    profileTripParams.data.search = search.value;
    await getParcelListing();
  };
  const getParcelDetails = async (id: string) => {
    try {
      loading.value = true;
      const res = await listingRepo.getParcelDetails(id);
      const blob = new Blob([res], { type: 'application/pdf' });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = 'Parcel-Invoice.pdf';
      link.click();
    } catch (error: any) {
      $toast.error(config.catchErrorMessage);
      loading.value = false;
    }
  };

  const getCharterDetails = async (id: string) => {
    try {
      loading.value = true;
      const res = await listingRepo.getCharterDetails(id);
      const blob = new Blob([res], { type: 'application/pdf' });

      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = 'Charter-Invoice.pdf';
      link.click();
    } catch (error: any) {
      $toast.error(config.catchErrorMessage);
      loading.value = false;
    }
  };
  const getPassDetails = async (id: string) => {
    try {
      loading.value = true;
      const res = await listingRepo.getPassDetails(id);
      const blob = new Blob([res], { type: 'application/pdf' });

      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = 'Pass.pdf';
      link.click();

      // if (res.status === 'success') {
      //   let invoicePath = import.meta.env.VITE_APP_INVOICE_PATH;
      //   // newsData.value = res.data;
      //   const path = invoicePath + res.data;
      //   window.open(path);
      //   loading.value = false;

      // } else {
      //   $toast.error(res.message);
      //   loading.value = false;
      // }
    } catch (error: any) {
      $toast.error(config.catchErrorMessage);
      loading.value = false;
    }
  };
  const showTicket = async (id: any) => {
    try {
      loading.value = true;
      const res = await listingRepo.showTicket(id);

      if (res.status === 'success') {
        rowData.value = res.data;
        loading.value = false;
      } else {
        $toast.error(res.message);
        loading.value = false;
      }
    } catch (error: any) {
      $toast.error(config.catchErrorMessage);
      loading.value = false;
    }
  };
  const parcelTicket = async (id: any) => {
    try {
      loading.value = true;
      const res = await listingRepo.parcelTicket(id);
      if (res.status === 'success') {
        rowData.value = res.data;
        loading.value = false;
      } else {
        $toast.error(res.message);
        loading.value = false;
      }
    } catch (error: any) {
      $toast.error(config.catchErrorMessage);
      loading.value = false;
    }
  };
  const charterTicket = async (id: any) => {
    try {
      loading.value = true;
      const res = await listingRepo.charterTicket(id);

      if (res.status === 'success') {
        rowData.value = res.data;
        loading.value = false;
      } else {
        $toast.error(res.message);
        loading.value = false;
      }
    } catch (error: any) {
      $toast.error(config.catchErrorMessage);
      loading.value = false;
    }
  };
  const showPass = async (id: any) => {
    try {
      loading.value = true;
      const res = await listingRepo.showPass(id);
      if (res.status === 'success') {
        rowData.value = res.data;
        loading.value = false;
      } else {
        $toast.error(res.message);
        loading.value = false;
      }
    } catch (error: any) {
      $toast.error(config.catchErrorMessage);
      loading.value = false;
    }
  };
  const downloadCharterTicket = async (id: any) => {
    try {
      loading.value = true;
      const res = await listingRepo.downloadCharterTicket(id);
      const blob = new Blob([res], { type: 'application/pdf' });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = 'Charter-Ticket.pdf';
      link.click();
    } catch (error: any) {
      $toast.error(config.catchErrorMessage);
      loading.value = false;
    }
  };
  const downloadTicket = async (id: any) => {
    try {
      loading.value = true;
      const res = await listingRepo.downloadTicket(id);
      const blob = new Blob([res], { type: 'application/pdf' });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = 'Ticket.pdf';
      link.click();
    } catch (error: any) {
      $toast.error(config.catchErrorMessage);
      loading.value = false;
    }
  };
  const downloadParcelTicket = async (id: any) => {
    try {
      loading.value = true;
      const res = await listingRepo.downloadParcelTicket(id);
      const blob = new Blob([res], { type: 'application/pdf' });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = 'Parcel-Ticket.pdf';
      link.click();
    } catch (error: any) {
      $toast.error(config.catchErrorMessage);
      loading.value = false;
    }
  };
  const downloadPass = async (id: any) => {
    try {
      loading.value = true;
      const res = await listingRepo.downloadPass(id);
      const blob = new Blob([res], { type: 'application/pdf' });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = 'Pass.pdf';
      link.click();
    } catch (error: any) {
      $toast.error(config.catchErrorMessage);
      loading.value = false;
    }
  };

  return {
    loading,
    portList,
    tripList,
    parcelTripList,
    passList,
    boatWiseVehicleList,
    boatWiseClassList,
    boatWisePetList,
    charterList,
    charterDetail,
    baseImageUrl,
    testimonialList,
    transactionDataList,
    profileTrips,
    profileTripParams,
    search,
    newsList,
    newsData,
    newsParams,
    topDestList,
    topNewsList,
    profileCharterList,
    profilePassList,
    profileParcelList,
    rowData,
    searchItems,
    limitChange,
    pageChange,
    clearSearch,
    getPortList,
    getTripList,
    getParcelTripList,
    getPassList,
    getBoatsVehicles,
    getBoatsPets,
    getBoatsClasses,
    getCharterList,
    getCharterDetail,
    showErrorImage,
    getTestimonialList,
    getTransactionList,
    getProfileTrips,
    getNewsList,
    getNews,
    getTopDestinations,
    getTopNews,
    getInvoice,
    getTripDetail,
    getPassListing,
    searchPass,
    passLimitChange,
    passPageChange,
    clearPassSearch,
    getCharterListing,
    searchCharter,
    charterLimitChange,
    charterPageChange,
    clearCharterSearch,
    getParcelListing,
    searchParcel,
    parcelLimitChange,
    parcelPageChange,
    clearParcelSearch,
    getParcelDetails,
    getCharterDetails,
    getPassDetails,
    showTicket,
    parcelTicket,
    charterTicket,
    showPass,
    downloadCharterTicket,
    downloadTicket,
    downloadParcelTicket,
    downloadPass
  };
};