import { api } from "~/http/Api";

export default {
  getPortList: () => api.get(`all-ports`, null, null, false),
  getTripList: (data: any) => api.post(`trips`, data, null, false),
  getParcelTripList: (data: any) =>
    api.post(`parcel-listing`, data, null, false),
  getPassList: (data: any) => api.post(`pass-listing`, data, null, false),
  getVehicles: () => api.get(`get-all-vehicles`, null, null, false),
  getSubVehicles: (id: string) => api.get(`vehicles/${id}`, null, null, false),
  getAllPetsList: () => api.get(`/all-pets`, null, null, false),
  getAllTravelClassList: () => api.get(`/all-travel-class`, null, null, false),
  getAllBoatVehicles: (id: string) =>
    api.get(`boats/${id}/vehicles`, null, null, false),
  getAllBoatPets: (id: string) =>
    api.get(`boats/${id}/pets`, null, null, false),
  getAllBoatClasses: (id: string) =>
    api.get(`boats/${id}/classes`, null, null, false),
  getCharterList: (data: any) => api.post(`charter-listing`, data, null, false),
  getCharterDetail: (id: any) => api.get(`charters/${id}`, null, null, false),
  getTestimonialList: () => api.get(`/testimonials-listing`, null, null, false),
  getTransactionList: (data: any) =>
    api.get(
      `transaction-listing?limit=` +
        data.limit +
        `&page=` +
        data.page +
        `&sort=` +
        data.sort +
        `&dir=` +
        data.dir +
        `&search=` +
        encodeURIComponent(data.search) +
        `&status=` +
        data.status +
        `&type=` +
        data.type +
        ``,
      null,
      null,
      true
    ),
  getProfileTrips: (data: any) =>
    api.get(
      `trip-listing?limit=` +
        data.limit +
        `&page=` +
        data.page +
        `&sort=` +
        data.sort +
        `&dir=` +
        data.dir +
        `&search=` +
        encodeURIComponent(data.search) +
        `&status=` +
        data.status +
        `&type=` +
        data.type +
        ``,
      null,
      null,
      true
    ),
  getNewsList: (data: any) =>
    api.get(
      `news?limit=` +
        data.limit +
        `&page=` +
        data.page +
        `&categorySlug=` +
        data.categorySlug +
        ``,
      null,
      null,
      false
    ),
  getNews: (categoryslug: string, titleslug: string) =>
    api.get(`news/${categoryslug}/${titleslug}`, null, null, false),
  getTransactionInvoice: (id: string) =>
    api.get(`transaction-details/${id}`, null, null, true),
  getTripDetail: (id: string) =>
    api.get(`trip/details/${id}`, null, null, true),
  getTopDestinations: () => api.get(`top-destinations`, null, null, false),
  getTopNews: () => api.get(`get-top-news`, null, null, false),
  getPassListing: (data: any) =>
    api.get(
      `pass-listing?limit=` +
        data.limit +
        `&page=` +
        data.page +
        `&sort=` +
        data.sort +
        `&dir=` +
        data.dir +
        `&search=` +
        encodeURIComponent(data.search) +
        `&status=` +
        data.status +
        `&type=` +
        data.type +
        ``,
      null,
      null,
      true
    ),
  getCharterListing: (data: any) =>
    api.get(
      `charter-listing?limit=` +
        data.limit +
        `&page=` +
        data.page +
        `&sort=` +
        data.sort +
        `&dir=` +
        data.dir +
        `&search=` +
        encodeURIComponent(data.search) +
        `&status=` +
        data.status +
        `&type=` +
        data.type +
        ``,
      null,
      null,
      true
    ),
    getParcelListing: (data: any) =>
      api.get(
        `parcel-listing?limit=` +
          data.limit +
          `&page=` +
          data.page +
          `&sort=` +
          data.sort +
          `&dir=` +
          data.dir +
          `&search=` +
          encodeURIComponent(data.search) +
          `&status=` +
          data.status +
          `&type=` +
          data.type +
          ``,
        null,
        null,
        true
      ),
      getParcelDetails: (id: string) =>
        api.get(`parcel/details/${id}`, null, null, true),
      getCharterDetails: (id: string) =>
        api.get(`charter/details/${id}`, null, null, true),
      getPassDetails: (id: string) =>
        api.get(`pass/details/${id}`, null, null, true),
      showTicket: (id: any) => api.get(`/ticket/${id}`,null,null,true),
      parcelTicket: (id: any) => api.get(`/parcel-ticket/${id}`,null,null,true),
      charterTicket: (id: any) => api.get(`/charter-ticket/${id}`,null,null,true),
      downloadCharterTicket: (id: any) => api.get(`/download-charter-ticket/${id}`,null,null,true),
      downloadTicket: (id: any) => api.get(`/download-ticket/${id}`,null,null,true),
      downloadParcelTicket: (id: any) => api.get(`/download-parcel-ticket/${id}`,null,null,true),
      showPass: (id: any) => api.get(`/pass/${id}`,null,null,true),
      downloadPass: (id: any) => api.get(`/download-pass/${id}`,null,null,true)
};
